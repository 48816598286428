<template>
  <Teleport to="body">
    <div v-for="(toasts, placement) in toastApi.toastsByPlacement" :key="placement">
      <div v-bind="toastApi.getGroupProps({ placement })">
        <Toast v-for="toastItem in toasts" :key="toastItem.id" :toast="toastItem" />
      </div>
    </div>
  </Teleport>

  <slot />
</template>

<script lang="ts" setup>
import * as toast from '@zag-js/toast'
import { normalizeProps, useMachine } from '@zag-js/vue'
import { computed, defineAsyncComponent, provide } from 'vue'

import { TOAST_CONTEXT_KEY } from '~/components/Toast/hooks'

const Toast = defineAsyncComponent(() => import('~/components/Toast/Toast.vue'))

const [state, send] = useMachine(toast.group.machine({ id: '1', gutter: '32px' }))
const toastApi = computed(() => toast.group.connect(state.value, send, normalizeProps))

provide(TOAST_CONTEXT_KEY, toastApi)
</script>
