import {
  ApiError,
  BaseHttpRequest,
  CancelablePromise,
  OpenAPIConfig,
  QuantistryAPIClient,
} from '@quantistry/api'
import { ApiRequestOptions } from '@quantistry/api/core/ApiRequestOptions'
import { request } from '@quantistry/api/core/request'
import { captureException } from '@sentry/vue'
import debug from 'debug'

import config from '../config'
import { noop } from '~/utils/utils'

const log = debug('quantistry:api')

/**
 * This function gets called on all ApiErrors thrown by the QuantistryAPIClient
 */
function onApiError(error: ApiError) {
  captureException(error, {
    level: error.status >= 500 ? 'error' : 'warning',
    tags: {
      api: true,
      'http.status': error.status,
      'http.method': error.request.method,
      // sanitize all UUIDs
      'http.route': new URL(error.url).pathname.replace(/[a-zA-Z0-9-]{32,36}/g, '{id}'),
    },
  })
}

/**
 * This rather cumbersome way allows us to globally intercept
 * requests issued by our QuantistryAPIClient.
 */
class InterceptedFetchHttpRequest extends BaseHttpRequest {
  constructor(config: OpenAPIConfig) {
    super(config)
  }

  request<T>(options: ApiRequestOptions) {
    return new CancelablePromise<T>(async (resolve, reject, onCancel) => {
      try {
        if (onCancel.isCancelled) {
          return
        }

        if (!isSessionValid()) {
          await getSession()
        }

        const promise = request<T>(this.config, options)
        onCancel(() => promise.cancel())
        const response = await promise
        resolve(response)
      } catch (error) {
        if (error instanceof ApiError) {
          onApiError(error)
        }
        reject(error)
      }
    })
  }
}

export const api = new QuantistryAPIClient(
  {
    // we use cookies
    WITH_CREDENTIALS: true,
    BASE: config.apiUrl.toString(),
  },
  InterceptedFetchHttpRequest
)

interface Session {
  user: {
    email: string
  }
  expires_at: string
  error?: 'RefreshAccessTokenError'
}

// this acts as a global lock for the session refresh
let sessionPromise: Promise<void> | null = null
let lastRefresh = Date.now()

/**
 * Check if the last check was less than 60 seconds ago.
 *
 * This is a synchronous function so we avoid the additional
 * microtask on each valid API request.
 */
function isSessionValid() {
  return Date.now() - lastRefresh < 60 * 1000
}

async function getSession() {
  if (sessionPromise) {
    // this blocks other requests until the session is refreshed
    log('Waiting for session refresh')
    await sessionPromise
    log('Done waiting for session refresh')
    return
  }

  const { promise, resolve } = getPromiseWithResolvers()
  sessionPromise = promise

  try {
    log('Refreshing session')
    const response = await fetch('/api/auth/session', { headers: { accept: 'application/json' } })
    const session: Session = await response.json()

    // reload the page on unrecoverable session refresh errors
    if (session === null || session?.error === 'RefreshAccessTokenError') {
      location.reload()
      return
    }
    lastRefresh = Date.now()
    log('Refreshed session')
    resolve()
  } catch (error) {
    // reload the page on unrecoverable session refresh errors
    location.reload()
  } finally {
    sessionPromise = null
  }

  return promise
}

export function isApiError(error: unknown): error is ApiError {
  return error instanceof ApiError
}

/**
 * `Promise.withResolvers` is not widely available yet, so we use a workaround.
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/withResolvers
 */
function getPromiseWithResolvers() {
  let resolve: (value: void | PromiseLike<void>) => void = noop
  let reject: (reason?: any) => void = noop
  const promise = new Promise<void>((res, rej) => {
    resolve = res
    reject = rej
  })

  return { promise, resolve, reject }
}
