import * as toast from '@zag-js/toast'
import { inject, InjectionKey, Ref } from 'vue'

type ToastApiInterface = ReturnType<typeof toast.group.connect>

export type ToastCardType = 'success' | 'error' | 'info' | 'warning'

const cardTypeToToastType: Partial<Record<ToastCardType, toast.Type>> = {
  success: 'success',
  error: 'error',
  info: 'info',
  warning: 'custom',
}

export const toastTypeToCardType: Partial<Record<toast.Type, ToastCardType>> = {
  success: 'success',
  error: 'error',
  info: 'info',
  custom: 'warning',
}

export const TOAST_CONTEXT_KEY: InjectionKey<Ref<ToastApiInterface>> = Symbol('toast-context')

export function useToast() {
  const toast = inject(TOAST_CONTEXT_KEY)

  function createToast(options: {
    title: string
    type: ToastCardType
    description: string
    duration?: number
  }) {
    if (!toast) {
      throw new TypeError(
        'Toast context unavailable. Please make sure your app is wrapped with a <ToastProvider />.'
      )
    }

    const { type: cardType } = options

    const toastParams = Object.assign(options, {
      type: cardTypeToToastType[cardType],
      placement: 'bottom-start' as const,
    })

    toast.value.create(toastParams)
  }

  return { createToast }
}
