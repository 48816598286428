import debug from 'debug'
import { onMounted, onUnmounted } from 'vue'
import { RouteLocationNormalized, useRouter } from 'vue-router'

const log = debug('quantistry:app-update')

/**
 * Listens for `vite:preloadError` events and reloads upon the next route change.
 * This updates the app when a preload error has occured.
 */
export function useAppUpdate() {
  const router = useRouter()

  let hasPreloadError = false
  let pendingNavigation: RouteLocationNormalized | null = null

  router.beforeEach((to, from, next) => {
    // keep track of the pending navigation in case this navigation triggers a preload error
    pendingNavigation = to

    if (hasPreloadError) {
      log('reloading due to preload error')
      window.location.assign(router.resolve(to).href)
    } else {
      next()
    }
  })

  router.afterEach(() => {
    pendingNavigation = null
  })

  function handlePreloadError(event: Event) {
    event.preventDefault()
    // @ts-expect-error custom payload
    console.error(event.payload)

    if (pendingNavigation) {
      log('reloading due to preload error')
      window.location.assign(router.resolve(pendingNavigation).href)
      return
    }

    hasPreloadError = true
  }

  onMounted(() => window.addEventListener('vite:preloadError', handlePreloadError))
  onUnmounted(() => window.removeEventListener('vite:preloadError', handlePreloadError))
}
