<template>
  <slot></slot>

  <ContactFormDialog
    v-if="isContactFormLoaded"
    :subject="contactFormData?.subject"
    :message="contactFormData?.message"
    :additional-information="contactFormData?.additionalInformation"
    :is-open="isContactFormOpen"
    @on-request-close="close"
  />
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

import { setupContactForm } from './'

const ContactFormDialog = defineAsyncComponent(() => import('./ContactFormDialog.vue'))
const { isContactFormLoaded, isContactFormOpen, contactFormData, close } = setupContactForm()
</script>
