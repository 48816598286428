<template>
  <slot name="trigger" :trigger-props="api.triggerProps" :merge-props="mergeProps" />

  <Teleport to="body">
    <div v-if="api.isOpen" v-bind="api.positionerProps">
      <div v-bind="api.arrowProps">
        <div v-bind="api.arrowTipProps" />
      </div>
      <div v-bind="api.contentProps"><slot name="tooltip" /></div>
    </div>
  </Teleport>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  inheritAttrs: false,
})
</script>

<script lang="ts" setup>
import { connect, Context, machine } from '@zag-js/tooltip'
import { mergeProps, normalizeProps, useMachine } from '@zag-js/vue'
import { nanoid } from 'nanoid'
import { computed, useAttrs } from 'vue'

type Placement = NonNullable<Context['positioning']>['placement']
const props = defineProps<{ placement?: Placement; gutter?: number }>()
const attrs = useAttrs()

const [state, send] = useMachine(
  machine({
    // allow for custom trigger id in case this is used within @headless ui components
    // which manage their own ids
    ids: attrs.id
      ? {
          trigger: attrs.id as string,
        }
      : undefined,
    id: nanoid(6),
    closeDelay: 250,
    positioning: { placement: props.placement, flip: true, gutter: props.gutter },
  })
)
const api = computed(() => connect(state.value, send, normalizeProps))
</script>

<style scoped>
[data-part='content'] {
  @apply max-w-[200px] rounded bg-neutral-700 px-2 py-1 text-sm text-white;
}

[data-part='arrow'] {
  --arrow-size: 8px;
  --arrow-background: var(--color-neutral-700);
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

[data-part='positioner'] {
  animation: fade-in 100ms ease-out;
}
</style>
