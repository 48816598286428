import * as VueRouter from 'vue-router'

import AppShell from './components/AppShell.vue'
import config from './config'
import { featureFlagsId } from '~/components/FeatureFlags/use-feature-flags'

// `meta.title` is used solely for matomo tracking

const routes: VueRouter.RouteRecordRaw[] = [
  { path: '/index.html', redirect: '/' },
  {
    path: '/auth/welcome',
    component: () => import('./pages/auth/AuthWelcomePage.vue'),
  },
  {
    path: '/auth/error',
    component: () => import('./pages/auth/AuthErrorPage.vue'),
  },
  {
    path: '/',
    component: AppShell,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        component: () => import('./pages/home/Home.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'workflows/:workflowId',
        component: () => import('./pages/workflows/_id/Viewer.vue'),
        meta: { requiresAuth: true, title: 'workflow-viewer' },
      },
      {
        path: '/create',
        component: () => import('./pages/TemplateGallery.vue'),
      },
      {
        path: '/create/compound',
        component: () => import('./pages/create/Compounds.vue'),
      },
      {
        path: '/create/preparation/prepare-liquid',
        component: () => import('./pages/create/preparation/PrepareLiquid.vue'),
      },
      {
        path: '/create/preparation/prepare-clusters',
        component: () => import('./pages/create/preparation/PrepareClusters.vue'),
      },
      {
        path: '/create/preparation/prepare-polymer',
        component: () => import('./pages/create/preparation/PrepareMarsoPolymer.vue'),
      },
      {
        path: '/create/preparation/prepare-crystal',
        component: () => import('./pages/create/preparation/PrepareCrystal.vue'),
      },
      {
        path: 'compounds/:compoundId',
        component: () => import('./pages/compounds/CompoundViewerPage.vue'),
        meta: { requiresAuth: true, title: 'compound-viewer' },
      },
      {
        path: 'compounds/new',
        component: () => import('./pages/compounds/PubChemCompoundViewer.vue'),
        meta: { requiresAuth: true, title: 'compound-preview-viewer' },
      },
      {
        path: 'preparations/:preparationId',
        component: () => import('./pages/preparations/Viewer.vue'),
        meta: { requiresAuth: true, title: 'preparation-viewer' },
      },
      {
        path: '/:affiliationId',
        component: () => import('./pages/AffiliationDashboard.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/:affiliationId/:projectId',
        component: () => import('./pages/projects/ProjectDashboard.vue'),
        meta: { requiresAuth: true },
        children: [
          {
            name: 'workflows',
            path: '',
            alias: 'workflows',
            component: () => import('./pages/projects/WorkflowView.vue'),
            meta: { requiresAuth: true },
          },
          {
            name: 'compounds',
            path: 'compounds',
            component: () => import('./pages/projects/CompoundView.vue'),
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: '/account',
        component: () => import('./pages/account/Account.vue'),
        meta: { requiresAuth: true },
      },
    ],
  },
]

routes.push({
  path: '/:pathMatch(.*)*',
  name: 'not-found',
  component: () => import('./pages/NotFoundError.vue'),
})

function createRouter() {
  return VueRouter.createRouter({
    history: VueRouter.createWebHistory(config.baseUrl),
    routes,
  })
}

export const router = createRouter()

router.beforeEach((to, from, next) => {
  if (to.meta.flag === undefined) {
    next()
  }

  if (to.meta.flag !== undefined) {
    const featList = localStorage.getItem(featureFlagsId.value)
    if (featList && JSON.parse(featList)?.[to.meta.flag as string] === true) {
      next()
    } else {
      next({ name: 'not-found' })
    }
  }
})
