<template>
  <component
    :is="as"
    ref="button"
    class="q-focus flex flex-shrink-0 items-center justify-center rounded text-neutral-500 transition-colors disabled:opacity-50 disabled:pointer-events-none"
    :class="{
      'h-7 w-7': size === 'base',
      '-m-1 p-1': size === 'sm',
      primary: variant === 'primary',
      default: variant === 'default',
      danger: variant === 'danger',
      dark: theme === 'dark',
    }"
  >
    <slot />
  </component>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

withDefaults(
  defineProps<{
    as?: string
    variant?: 'primary' | 'default' | 'danger'
    size?: 'base' | 'sm'
    theme?: 'dark' | 'light'
  }>(),
  { size: 'base', variant: 'default', theme: 'light', as: 'button' }
)

const button = ref<HTMLButtonElement>()

defineExpose({
  focus(options?: FocusOptions) {
    if (button.value) {
      button.value.focus(options)
    }
  },
})
</script>

<style scoped>
:deep(svg) {
  @apply h-4 w-4;
}

.primary {
  @apply bg-white text-neutral-500 hover:bg-neutral-100;

  &:active,
  &[aria-pressed='true'] {
    background: linear-gradient(159deg, #004ba0 -74.63%, #3d93f5 116.92%);
    color: white;
  }
}

.default {
  @apply text-neutral-500 hover:bg-neutral-100 hover:text-neutral-700 active:bg-neutral-200;
}

.danger {
  @apply text-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 active:text-white;
}

.dark {
  @apply text-white;

  &:hover {
    @apply bg-primary-500 text-white;
  }
}
</style>
