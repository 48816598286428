import createDebug from 'debug'
import { App, inject } from 'vue'
import VueMatomo, { matomoKey } from 'vue-matomo'
import type { Router } from 'vue-router'

import config from '../config'

const log = createDebug('quantistry:matomo')

/**
 * This is only part of the matomo API. There's more:
 * https://developer.matomo.org/api-reference/tracking-javascript
 */
export type MatomoInstance = {
  /**
   * Log a page view
   */
  trackPageView(customTitle?: string): void

  /**
   * Log an event with
   * - an eventcategory (Videos, Music, Games...)
   * - an event action (Play, Pause, Duration, Add Playlist, Downloaded, Clicked...)
   * - an optional event name
   * - an optional numeric value
   */
  trackEvent(category: string, action: string, name?: string, value?: number): void

  /**
   * Log an internal site search for a specific keyword, in an optional category, specifying the optional count of search results in the page.
   */
  trackSiteSearch(keyword: string, category?: string, resultsCount?: number): void

  /**
   * Log a conversion for the numeric goal ID, with an optional numeric custom revenue customRevenue.
   */
  trackGoal(idGoal: number, customRevenue?: number): void
}

export function registerMatomo(app: App, router: Router) {
  if (!config.enableTracking) {
    return
  }

  app.use(VueMatomo, {
    // Configure your matomo server and site by providing
    host: 'https://analytics.quantistry.com',
    siteId: config.matomoId,

    // Changes the default .js and .php endpoint's filename
    // Default: 'matomo'
    trackerFileName: 'matomo',

    // Overrides the autogenerated tracker endpoint entirely
    // Default: undefined
    // trackerUrl: 'https://example.com/whatever/endpoint/you/have',

    // Enables automatically registering pageviews on the router
    router: router,

    // Enables link tracking on regular links. Note that this won't
    // work for routing links (ie. internal Vue router links)
    // Default: true
    enableLinkTracking: false,

    // Require consent before sending tracking information to matomo
    // Default: false
    requireConsent: false,

    // Whether to track the initial page view
    // Default: true
    trackInitialView: true,

    // Whether or not to log debug information
    // Default: false
    debug: config.isDev,

    // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
    // Default: undefined
    // userId: store.state.user,
  })
}

const noopTracker = new Proxy({} as MatomoInstance, {
  get(_, prop) {
    return (...args: any[]) => {
      // print some debug logging in dev mode
      if (config.isDev) {
        log(`${String(prop)}():`, ...args)
      }
    }
  },
})

export function useMatomo(): MatomoInstance {
  if (!config.enableTracking) {
    return noopTracker
  }
  const matomo = inject(matomoKey) as MatomoInstance

  // user is probably using an ad blocker
  if (!matomo) {
    return noopTracker
  }
  return matomo
}
